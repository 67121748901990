export const fields = [

    { key: 'full_name', label: "Nama", _style:'min-width:100px' },
    { key: 'nip', label: "NIP", _style:'min-width:100px' },
    { key: 'email', label: "E-Mail", _style:'min-width:100px' },
    { key: "group", label: "Golongan", _style:'min-width:50px'},
    { key: 'role', _style:'min-width:100px;' },
    { key: 'position', label: "Jabatan", _style:'min-width:100px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
